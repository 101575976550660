import FormModal from "@/plugins/app/_layout/z-form-modal.vue"
import { open } from "@/plugins/lib@util/ion-modals/models/modals";
import { differenceInSeconds, parseISO } from 'date-fns'

const time = (time, minutes, hours, date) => {
	if (time === undefined) return time

	let isoTime
	if (typeof time == "string" && !isNaN(isoTime = parseISO(time.toString())))
		return isoTime

	return Date.time(time, minutes, hours, date)
}

const durationOf = (from, to) => {
	const diff = differenceInSeconds(time(to), time(from))
	return time(diff < 0 ? 86400 + diff : diff)
}

const updateInputs = (allInputs, inputIndex, minutes = 1) => {
	if (allInputs.start.value == allInputs.end.value) // If start and end are same (duration 0)
		allInputs[inputIndex].value = time(allInputs[inputIndex].value).add({ minutes }).format("HH:mm") // Add given amount of minutes to prevent 0 duration

	allInputs.duration.value = durationOf(allInputs.start.value, allInputs.end.value).format("HH:mm") // Update duration
}

const generateInputData = (_data) => {
	const now = new Date();
	_data = Object.assign({}, {
		start: now.toISOString(),
		end: now.add({ minutes: 1 }).toISOString(),
		comment: "",
		billable: true
	}, _data)
	return { ..._data, date: time(_data.date ?? _data.start).format("yyyy-MM-dd"), start: time(_data.start).format("HH:mm"), end: time(_data.end).format("HH:mm") }
}

export const input = (name, value, type = "text", onChange, props) => ({ name, value, type, onChange, props })

export default async function(_data, title = "Pick time", deleteAction = null, otherInputs = null, validator = null) {
	_data = generateInputData(_data)

	const props = { readonly: window.innerHeight < 900 }
	const inputs = {
		date: input("Date:", _data.date, "date", null, props), 
		start: input("Start:", _data.start, "time", (_, allInputs) => updateInputs(allInputs, "end"), props), 
		end: input("End:", _data.end, "time", (_, allInputs) => updateInputs(allInputs, "start", -1), props),
		
		duration: input("Duration: ", durationOf(_data.start, _data.end).format("k'h' mm'm'"), "label"),
		comment: input("Comment:", _data.comment),
		billable: input("Billable:", _data.billable, "checkbox") ,
		...otherInputs,
	}

	const headerBtn = deleteAction ? { icon: "trashOutline", click: deleteAction } : null
	const modal = await open(FormModal, { inputs, title, headerBtn, validator });

	const { data } = await modal.onDidDismiss();
	if (!data) return;
	
	const { date, start, end, comment, billable } = data, isOverNight = start > end
	return { date, start: time(start, 0, 0, date), end: time(end, 0, 0, date).add({ days: +isOverNight }), comment, billable }
}