import loginGuardIsLoggedIn from '@/plugins/w/auth/loginGuardIfMetaSet'

// eslint-disable-next-line
export default(title: string, path: string, component, meta) => {
	return {
		path,
		name: title,
		meta: { title, ...meta },
		component: component,
		beforeEnter: loginGuardIsLoggedIn
	}
}