<template>
	<div class="p-0">
		<div
			class="flex ion-justify-content-start px-1 py-1"
			style="margin: 5px 15px"
		>
			<ion-skeleton-text animated class="text"></ion-skeleton-text>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.text
	width: calc(100% - 60px)
	height: 70px
	width: 100%

</style>
