<template>
	<ion-page>
		<A-header v-model="searchQuery" @toggleSearch="closeModal" />

		<ion-content :scroll-y="false">
			<Search-overlay :searchQuery="searchQuery" :url="url" 
							@projectClicked="allowSelectProject ? closeModal($event) : null" @taskClicked="allowSelectTask ? closeModal($event) : null" />
		</ion-content>
	</ion-page>
</template>

<script>
import AHeader from '@/plugins/app/_layout/a-header.vue'

export default {
	components: { AHeader },

	props: {
		url: {
			type: String,
			default: "/searchProjects"
		},

		allowSelectProject: {
			type: Boolean,
			default: true
		},

		allowSelectTask: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			searchQuery: ""
		}
	},

	methods: {
		closeModal(data) {
			if (!data || data._id)
				return this.$modals.dismiss(data?._id ? data : null)
		}
	}
}
</script>

<style lang="sass" scoped>
.ion-page
	background: darkblue !important
</style>