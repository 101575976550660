import axios from 'axios'
import store from '@/plugins/app/_config/store'

let pendingRefresh = null;
let pendingRequests = [];

// Auth refresh interceptor + refresh logic
axios.interceptors.response.use(resp => resp, err => {
	const { config, response: { status } } = err;
	const originalRequest = config;

	if (status != 401 || originalRequest.isRefreshing) 
		return Promise.reject(err);

	if (!pendingRefresh) {
		pendingRefresh = store.dispatch('auth/refreshToken').then(newToken => {
			pendingRequests.map(cb => cb(newToken));
			pendingRequests = [];
			pendingRefresh = null;
		});
	}

	return new Promise(resolve => {
		pendingRequests.push(token => {
			originalRequest.isRefreshing = true;
			originalRequest.headers.Authorization = `Bearer ${token}`;
			resolve(axios(originalRequest));
		});
	});
});