<template>
	<ion-page>
		<ion-header>
			<ion-toolbar class="flex ion-justify-content-center">
				<h1 class="ion-text-center mt-0 mb-0" @dblclick="editName()">
					{{ name }}
					<ion-icon v-if="hasEditableName" class="edit-name-icon" :icon="$icons.pencil" @click="editName()" />
				</h1>
				<ion-icon @click="closeModal()" slot="end" class="close-modal-icon" :icon="$icons.close" size="large" />

				<div slot="start">
					<slot name="header"></slot>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content :scroll-y="isScrollable">
			<slot></slot>
		</ion-content>

		<ion-footer class="ion-no-border">
			<ion-toolbar>
				<div class="flex">
					<slot name="footer"></slot>
				</div>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: "Modal"
		},
		hasEditableName: {
			type: Boolean,
			default: false
		},
		isScrollable: {
			type: Boolean,
			default: true
		}
	},

	emits: ["editName", "closeClicked"],

	methods: {
		editName() {
			if (this.hasEditableName)
				this.$emit("editName")
		},

		closeModal() {
			this.$emit("closeClicked")
			this.$modals.dismiss()
		}
	}
}
</script>

<style lang="sass" scoped>
ion-header
	h1
		font-size: 18px

	ion-toolbar
		--border: none
		--border-style: none
		--background: transparent
		--padding-top: 10px

		ion-icon
			cursor: pointer

		.edit-name-icon
			font-size: 18px
			margin-bottom: 5px
			color: gray
			margin-top: auto
			margin-bottom: auto

		h1
			padding: 0 15px

		.close-modal-icon
			width: 26px
			height: 26px

ion-icon
	
	&:before
		content: ''
		position: absolute
		width: 100%
		height: 100%
</style>









