import { modalController } from '@ionic/vue'

export { modalController } 

export let currentlyOpened

export async function open(component, componentProps, config?) {
	if (component == currentlyOpened)
		return { onDidDismiss: () => ({}) }
	
	const modal = await modalController.create({
		component,
		initialBreakpoint: window.innerWidth >= 768 && window.innerHeight >= 600 ? undefined : 1,
		breakpoints: [0, 1],
		handle: true,

		componentProps,
		...config
	})

	modal.onDidDismiss().then(() => currentlyOpened = null)
	
	modal.present()
	currentlyOpened = component
	return modal
}