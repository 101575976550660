<template>
	<Z-modal :name="title">
		<template v-if="headerBtn" #header>
			<ion-icon class="size-xl" :icon="$icons[headerBtn.icon]" @click="additionalAction" /> 
		</template>

		<div class="content" ref="content">
			<template v-for="input in dataInputs" :key="input">
				<ion-text v-if="input.type == 'label'" v-bind="input.props" @click="input.onClick?.(input, dataInputs)">{{input.name + input.value}}</ion-text>

				<ion-item v-else-if="input.type == 'text'" :id="input.id" class="my-1 ion-no-padding" @click="input.onClick?.(input, dataInputs)">
					<ion-label :position="input.position ?? 'floating'">{{input.name}}</ion-label>
					<ion-input :placeholder="input.placeholder ?? 'Enter text'" v-model="input.value" v-bind="input.props" @ionChange="onChange(input, dataInputs)" />
				</ion-item>

				<ion-item v-else-if="input.type == 'checkbox'" :id="input.id" class="my-1 ion-no-padding" @click="input.onClick?.(input, dataInputs)">
					<ion-label>{{input.name}}</ion-label>
					<ion-toggle slot="end" v-model="input.value" v-bind="input.props" @ionChange="onChange(input, dataInputs)" />
				</ion-item>

				<Z-input v-else v-model="input.value" :name="input.name" :type="input.type" :id="input.id" :props="input.props" :hasPopover="input.hasPopover" :underline="true"
							:pattern="input.pattern" :debounce="input.debounce" class="mb-1" @update:modelValue="onChange(input, dataInputs)" @click="input.onClick?.(input, dataInputs)" />
			</template>
		</div>

		<template #footer>
			<ion-button class="w-full cancel" @click="closeModal()">Cancel</ion-button>
			<ion-button class="w-full" @click="closeModal(dataInputs)">Select</ion-button>
		</template>
	</Z-modal>
</template>

<script>
import ZInput from "./z-input.vue"

export default {
	props: {
		title: {
			type: String,
			default: 'Pick time'
		},

		inputs: {
			type: Object,
			default() {
				return [
					{
						name: "Date:",
						type: "date",
						value: "2022-01-01"
					},
					{
						name: "Start:",
						type: "time",
						value: "07:30"
					},
					{
						name: "End:",
						type: "time",
						value: "08:00"
					},
				]
			}
		},

		validator: {
			type: Function
		},

		headerBtn: {
			type: Object
		}
	},

	emits: ['change'],

	components: { ZInput },

	data() {
		return {
			dataInputs: [],
			clickEvent: null
		}
	},

	mounted() {
		this.dataInputs = this.inputs

		window.addEventListener("keyup", this.closeOnEnter)
	},

	unmounted() {
		window.removeEventListener('keyup', this.closeOnEnter);
	},

	methods: {
		onChange(input, inputs) {
			input?.onChange?.(input.value, inputs)
			this.$emit("change", input.value, inputs)
		},

		async additionalAction(ev) {
			const close = await this.headerBtn?.click(ev)
			if (close)
				this.closeModal()
		},

		closeOnEnter(ev) {
			if (ev.code == "Enter")
				this.closeModal(this.dataInputs);
		},

		closeModal(data) {
			if (!data)
				return this.$modals.dismiss()

			const values = {}
			Object.keys(data).forEach(key => values[key] = data[key].value)
			if (!this.validator || this.validator?.(data)) // Dismiss only if validated
				this.$modals.dismiss(values)
		}
	}
}
</script>

<style lang="sass" scoped>
ion-popover
	--width: 300px !important

	*
		user-select: none

ion-item
	ion-input
		border: none !important

ion-icon
	cursor: pointer

.cancel
	--color: #0449FF !important
	min-height: 40px !important
	margin-bottom: 0px !important
	--background: #ffffff !important
	--ion-color-base: none !important
	--border-style: solid
	--border-color: #0449FF
	--border-width: 1.5px

.content
	box-shadow: inset 0 6px 8px -8px rgb(0 0 0 / 30%)
	padding: 10px 13px 0 !important
	overflow-y: auto
	height: 100%

	h2
		margin: 0 0 1px
		font-size: 18px
		color: var(--ion-color-medium)
		line-height: 18px

	ion-text
		color: var(--ion-color-medium)

	::v-deep ion-input .native-input
		cursor: pointer

	ion-label
		color: var(--ion-color-medium)
		outline: none

.fade-enter-active, .fade-leave-active 
	transition: 0.5s

.fade-enter-from, .fade-leave-to
	opacity: 0
</style>