<template>
	<ion-card class="search-project-card">
		<div class="flex ion-align-items-center wrapper-header px-1 py-1">
			<img class="mr-2" src="@/plugins/app/_assets/wzo.jpg" />
			<div>
				<h4 class="company">{{ info?.companyName }}</h4>
				<h4>{{ name }}</h4>
			</div>
		</div>
	
		<div @click.stop>
			<slot></slot>
		</div>
	</ion-card>
</template>

<script>
export default {
	props: {
		name: {
			type: String
		},
		info: {
			type: Object
		}
	}
}
</script>

<style lang="sass" scoped>
[theme="dark"]
	.search-project-card
		border: 1px solid var(--ion-color-medium-tint)

.search-project-card
	width: calc( 100% - 30px )
	margin: 15px auto
	border-radius: 8px
	cursor: pointer
	box-shadow: 0px 0px 10px var(--ion-color-light-tint)

	img
		width: 50px
		height: 50px
		object-fit: cover
		border-radius: 100px
		border: 1px solid var(--ion-color-medium-tint)

	h4
		margin: 0
		font-size: 17px
		line-height: 17px
		color: var(--ion-text-color)

	.wrapper-header
		border-bottom: 1px solid var(--ion-color-medium-tint)
		flex: 0 0 auto

		h6.company
			font-size: 16px
			line-height: 16px
			margin-bottom: 5px
			color: var(--ion-color-medium-tint)
</style>