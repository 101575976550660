import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import wRoute from '@/plugins/w/route'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/:catchAll(.*)',
		redirect: () => "/" + (localStorage["prefered-route"] ?? "home")
	},
	wRoute('home', '/home', () => import('@/plugins/lib@home/home.vue'), {requiresAuth: true}),
	wRoute('search-unassigned', '/unassigned', () => import('@/plugins/lib@home/search-unassigned-tasks.vue'), {requiresAuth: true}),
	wRoute('analytics', '/analytics', () => import('@/plugins/lib@analytics/analytics.vue'), {requiresAuth: true}),
	wRoute('login', '/login', () => import('@/plugins/lib@auth/login.vue'), {requiresAuth: false, forbitWhenAuth: true}),
	wRoute('my-tasks', '/mytasks', () => import('@/plugins/lib@mytasks/my-tasks.vue'), {requiresAuth: true}),
	wRoute('projects', '/projects', () => import('@/plugins/lib@projectdetail/project-grid.vue'), {requiresAuth: true}),
	wRoute('project-detail', '/projects/:id', () => import('@/plugins/lib@projectdetail/project-detail.vue'), {requiresAuth: true}),
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
