<template>
  <h2 class="pb-1">Profile:</h2>
	<ion-item>
		<ion-avatar slot="start">
			<img src="../_assets/avatar.svg" />
		</ion-avatar>
		<div>
			<ion-label>{{ getUser?.name ?? 'Guest' }}</ion-label>
			<p class="ion-no-margin">{{ getUser?.emails?.[0] ?? 'guest@wezeo.com' }}</p>
		</div>
	</ion-item>

	<h2 class="mt-3">Allow popups:</h2>
	<ion-item>
		<ion-label>Stop tracking popup</ion-label>
		<ion-toggle slot="end" :checked="isConfirmStopPopup()" @ionChange="setConfirmStopPopup" />
	</ion-item>

	<ion-item>
		<ion-label>Task switch popup</ion-label>
		<ion-toggle slot="end" :checked="isTaskSwitchPopup()" @ionChange="setTaskSwitchPopup" />
	</ion-item>

	<h2 class="mt-3">Preferred screen:</h2>
	<ion-item>
		<ion-select :value="getPreferedScreen()" interface="popover" @ionChange="setPreferedScreen">
			<ion-select-option value="home">My projects</ion-select-option>
			<ion-select-option value="mytasks">My tasks</ion-select-option>
			<ion-select-option value="projects">Projects</ion-select-option>
			<ion-select-option value="analytics">Analytics</ion-select-option>
		</ion-select>
	</ion-item>

	<h2 class="mt-3">Theme:</h2>
	<ion-item>
		<ion-select :value="getTheme()" interface="popover" @ionChange="setTheme">
			<ion-select-option value="dark">Dark (beta)</ion-select-option >
			<ion-select-option value="light">Light</ion-select-option>
		</ion-select>
	</ion-item>

	<h2 class="mt-3">Data caching:</h2>
	<ion-item>
		<ion-label>Local storage cache</ion-label>
		<ion-toggle slot="end" :checked="isLocalStorageCache()" @ionChange="setCache" />
	</ion-item>

	<div class="mt-auto">
		<p class="ion-margin mb-1">App version: {{$appVersion}}</p>
		<div class="horizontal-separator"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as settings from '../_config/settings'

export default {
	computed: {
		...mapGetters('projectsStore', ['getUser']),
	},

	methods: {
		...settings,

		setCache(ev) {
			if (this.setLocalStorageCache(ev))
				localStorage.removeItem('projects-cache')
		}
	}
}
</script>

<style lang="sass" scoped>
h2
	margin: 0
	margin-left: 8px
	font-size: 18px
	color: var(--ion-color-medium)
	line-height: 18px

ion-label, ion-select
	font-weight: bold

img
	width: 60px

.horizontal-separator
	width: 100%
	border-bottom: 1px solid var(--ion-color-medium-tint)
	margin: 3px 0 !important
</style>