<template>
	<div class="p-0">
		<div class="flex ion-justify-content-start px-1 py-1" style="margin: 5px 15px;">
			<ion-skeleton-text animated class="avatar"></ion-skeleton-text>
			<ion-skeleton-text animated class="text"></ion-skeleton-text>
		</div>

		<div class="flex ion-justify-content-start px-1 py-1" style="margin: 5px 15px;">
			<ion-skeleton-text animated class="avatar"></ion-skeleton-text>
			<ion-skeleton-text animated class="text"></ion-skeleton-text>
		</div>

		<div class="flex ion-justify-content-start px-1 py-1" style="margin: 5px 15px;">
			<ion-skeleton-text animated class="avatar"></ion-skeleton-text>
			<ion-skeleton-text animated class="text"></ion-skeleton-text>
		</div>

		<div class="flex ion-justify-content-start px-1 py-1" style="margin: 5px 15px;">
			<ion-skeleton-text animated class="avatar"></ion-skeleton-text>
			<ion-skeleton-text animated class="text"></ion-skeleton-text>
		</div>

		<div class="flex ion-justify-content-start px-1 py-1" style="margin: 5px 15px;">
			<ion-skeleton-text animated class="avatar"></ion-skeleton-text>
			<ion-skeleton-text animated class="text"></ion-skeleton-text>
		</div>	
	</div>
</template>

<style lang="sass" scoped>
.avatar
	width: 20px
	height: 20px
	border-radius: 5px
	margin-right: 20px
	align-self: center

.text
	width: calc(100% - 60px)
	height: 20px

</style>