<template>
	<div class="relative">
		<ion-item v-if="underline" class="ion-no-padding" @click.prevent="togglePicker($event, true)">
			<ion-label position="floating">{{name}}</ion-label>
			<ion-input :value="value" :type="hasPicker() ? 'text' : type" :id="id" :placeholder="placeholder"
				@ionInput="onChange" class="ion-no-padding" v-bind="props" @keydown="onKey" />
		</ion-item>

		<template v-else>
			<h2 v-if="name?.length">{{name}}</h2>
			<ion-input :value="value" :type="hasPicker() ? 'text' : type" :id="id" :placeholder="placeholder"
				@ionInput="onChange" @click.prevent="togglePicker($event, true)" class="ion-no-padding" v-bind="props" @keydown="onKey" />
		</template>

		<ion-popover v-if="hasPicker()" :is-open="isPicker" :event="clickEvent" :showBackdrop="false" :keyboard-close="false" @didDismiss="togglePicker(null, false)">
			<ion-datetime :value="value" :presentation="type" size="cover" first-day-of-week="1" hourCycle="h23" :id="id ? (id + '-picker') : ''" @ionChange="update($event.target.value)" />
		</ion-popover>
	</div>
</template>

<script>
const dataPattern = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/, timePattern = /^([01][0-9]|2[0-3]):([0-5][0-9])$/

export default {
	props: {
		modelValue: {
			type: String
		},
		id: {
			type: String
		},
		type: {
			type: String,
			default: "text"
		},
		name: {
			type: String
		},
		placeholder: {
			type: String
		},

		debounce: {
			type: Number,
			default: 1200
		},
		hasPopover: {
			type: Boolean,
			default: undefined
		},
		pattern: {
			type: Object
		},
		props: {
			type: Object
		},

		underline: {
			type: Boolean
		}
	},

	emits: ["update:modelValue"],

	data() {
		return {
			isPicker: false,
			clickEvent: null,

			value: null,
			oldValue: null
		}
	},

	created() {
		this.oldValue = this.value = this.modelValue
	},

	watch: {
		modelValue() {
			this.oldValue = this.value = this.modelValue
		}
	},

	methods: {
		hasPicker() {
			return this.hasPopover ?? (this.type == 'date' || this.type == 'time')
		},

		isValid(value) { 
			const pattern =  this.pattern ?? (this.type == 'date' ? dataPattern : this.type == 'time' ? timePattern : null)
			return !pattern || pattern.test(value)
		},

		onKey(ev) {
			if (ev.code == "Enter")
				return this.togglePicker(null, false)

			const dir = ev.code == "ArrowDown" ? -1 : ev.code == "ArrowUp" ? 1 : 0
			if (dir == 0 || !this.hasPicker()) 
				return

			const caret = ev.target.selectionStart
			if (this.type == "time") {
				const add = { [caret > this.value.length/2 ? "minutes" : "hours"]: dir * (ev.shiftKey ? 10 : 1) }
				this.update(Date.time(this.value).add(add).format("HH:mm"))
			}
			else if (this.type == "date") {
				const add = { [caret > 7 ? "days" : "months"]: dir * (ev.shiftKey ? 10 : 1) }
				this.update(new Date(this.value).add(add).format("yyyy-MM-dd"))
			}

			ev.preventDefault()
			setTimeout(() => ev.target.selectionEnd = ev.target.selectionStart = caret, 18)
		},

		onChange(ev) {
			const newV = ev.target.value
			if (newV == null || !this.isValid(newV)) {
				clearTimeout(this.debounceTimer)
				return this.debounceTimer = setTimeout(() => ev.target.value = this.oldValue, this.debounce)
			}

			this.update(newV)
		},

		update(val) {
			this.$emit('update:modelValue', this.oldVal = this.value = val)
		},

		togglePicker(ev, isOpen) {
			if (this.isPicker = isOpen) {
				this.clickEvent = ev
			}
		},
	}
}
</script>

<style lang="sass" scoped>
ion-popover
	--width: 300px

	@media (max-width: 640px)
		--box-shadow: 0 0 12px -3px var(--ion-color-medium)

ion-item
	ion-input
		border: none !important


ion-label
	color: var(--ion-color-medium) !important
	outline: none !important
</style>