<template>
	<ion-card class="flex ion-align-items-center px-1 py-1" :class="{'completed': task.completed, 'active': isActive}">
		<ion-progress-bar v-if="pressStart || isActive" class="w-100 absolute tracking-indicator" :value="holdPercentage" />
		<ion-checkbox v-if="isCompletable && !task.unassigned && !treatAsUnassigned" @ionChange="toggleCompleted" :checked="task.completed" class="mr-1" />

		<div class="task-info" v-touch:hold="taskHold" v-touch:release="taskRelease" v-touch:drag="taskSlide">
			<h5 :class="{'font-bold': showProject}">{{ task.name }}</h5>
			<h5 v-if="showProject">Project: {{ projectName ?? project?.name }}</h5>

			<h6 v-if="task.unassigned">Unassigned</h6>
			<div v-else-if="isActive" class="time-tracking-wrapper w-fit flex items-center mt-0.5">
				<ion-icon :icon="$icons.timerOutline" />
				<p>{{ Date.time(totalTime).format() }}</p>
			</div>
			<h6 v-else-if="totalTime">Tracked time: {{ Date.time(totalTime).format("k'h' mm'm'") }}</h6>
		</div>
		<ion-progress-bar v-if="task.plannedTime" :value="progress" :color="progress > 1 ? 'warning' : 'success'" class="w-100 absolute progress-indicator"/>
	</ion-card>
</template>

<script>
import ModalTask from './components/modals/modal-task.vue'

import { mapGetters, mapActions } from 'vuex'

import axios from '@/plugins/w/axios/models/axios'
const api = {
	updateTask: async (taskId, newData) => await axios.patch_auth_data(`/tasks/${taskId}`, newData)
}

export default {
	props: {
		task: {
			required: true,
			type: Object
		},

		project: {
			type: Object
		},

		projectName: {
			type: String
		},

		hasModal: {
			type: Boolean,
			default: true
		},

		startTrackingAction: {
			type: Function
		},

		isCompletable: {
			type: Boolean,
			default: true
		},

		showProject: {
			type: Boolean,
			default: false
		},

		treatAsUnassigned: {
			type: Boolean,
			default: false
		}
	},
	
	data() {
		return {
			currentTime: [],

			pressStart: null,
			holdPercentage: 0,
		}
	},

	async created() {
		await this._updateTaskTracking()
	},

	emits: ['clicked'],

	computed: {
		...mapGetters('projectsStore', ['getActiveTask', 'getCurrentTrackedTime']),

		totalTime() {
			return this.isActive ? this.task.totalTime + this.getCurrentTrackedTime : this.task.totalTime
		},

		progress() {
			return this.totalTime / this.task.plannedTime
		},

		isActive() {
			return this.getActiveTask?._id == this.task._id
		}
	},

	methods: {
		...mapActions('projectsStore', ['startTracking', 'stopTracking', 'stopTrackingAction']),

		async _updateTaskTracking() {
			this.holdPercentage = this.isActive ? 1 : 0
		},

		async toggleCompleted(ev) {
			try {
				if (ev.target.checked && this.isActive) {
					await this.stopTrackingAction()
				}

				const updatedTask = await api.updateTask(this.task._id, { completed: ev.target.checked })
				this.$eventBus.emit('updateTask', updatedTask)
			} catch (err) {
				console.error(err);
			}
		},

		async openModalTask() {
			await this.$modals.open(ModalTask, {
				task: this.task,
				project: this.project,
				isUnassigned: this.task.unassigned || this.treatAsUnassigned
			})
		},

		// ----- Task Hold Actions ------

		taskHold() {
			this.pressStart = new Date()
			if (this.task.completed) return
			
			setTimeout(() => this.holdPercentage = this.isActive ? 0 : 1, 10)

			this.holdTimer = setTimeout(async () => {
				if (this.isActive) {
					await this.stopTracking()
				} 
				else {
					await this.startTrackingAction?.(this.task) || await this.startTracking(this.task)
				}

				this._updateTaskTracking()
				this.holdTimer = null
			}, 500)
		},

		taskSlide(ev) {
			if (Math.abs(ev.movementX + ev.movementY) > 2) {
				this.taskRelease()
			}
		},

		async taskRelease(ev) {
			const clicked = ev?.cancelable && new Date() - this.pressStart < 180
			if (clicked)
			{
				this.$emit("clicked", ev)

				if (this.hasModal)
					await this.openModalTask()
			}

			clearTimeout(this.holdTimer)
			this.holdTimer = null

			this._updateTaskTracking()
		}
	},

	watch: {
		getActiveTask() {
			this._updateTaskTracking()
		}
	}
}
</script>

<style lang="sass" scoped>
*
	user-select: none !important

ion-card
	border: 1px solid var(--ion-color-medium-tint)
	border-radius: 6px
	margin: 0
	margin-bottom: 10px
	box-shadow: none
	cursor: pointer
	position: relative

	&.active
		padding-bottom: 0
		padding-top: 0

	.tracking-indicator
		top: 0
		left: 0 
		height: 4px
		--background: transparent
		border-radius: 6px 6px 0 0

		&::part(progress)
			transition: ease-in 0.52s !important

	.progress-indicator
		bottom: 0
		left: 0
	
	.task-info
		flex: 1 1 100%

		h5
			color: var(--ion-text-color)
			font-weight: 400
			margin: 0
			word-break: break-word

		h6
			margin: 5px 0 0
			font-weight: 400

	.play-icon
		width: 23px
		height: 23px

	.stop-icon
		width: 30px
		height: 30px

.completed
	opacity: 0.5

	ion-checkbox
		--border-color: 1px solid var(--ion-color-medium-tint)
		--border-color-checked: 1px solid var(--ion-color-medium-tint)
		--checkmark-color: var(--ion-color-medium-tint)
		--background-checked: transparent

.time-tracking-wrapper
	padding: 2px 5px

	border: 1px solid var(--ion-color-medium-tint)
	border-radius: 4px

	ion-icon
		margin-right: 5px

	p
		margin: 0
		width: fit-content

</style>