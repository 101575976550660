<template>
	<ion-page>
		<A-header v-model="searchQuery" :title="title" :hasSearch="hasSearch" :searchActive="searchActive" :backArrow="backArrow" :addAction="finalAddAction" @enterPressed="searchFirst()" @toggleSearch="toggleSearch" ref="header" />

		<div v-if="error" class="bg-light w-full h-full flex flex-col ion-justify-content-center ion-align-items-center">
			<img src="@/plugins/app/_assets/error.svg">
			<h3 class="mt-2 mr-2 ml-2 text-center">Pri načítaní dát nastala chyba, skúste to znova.</h3>
			<p class="mt-0 mb-4">{{error?.status ? `Code ${error.status}` : error.message ?? error}}</p>
			<ion-button color="primary" @click="refresh()">Skúsiť znova</ion-button>
		</div>

		<template v-else>
			<slot name="subheader"></slot>

			<ion-content :scroll-y="lockYScrolling" scrollEvents @ionScroll="contentScrolled" ref="content">
				<slot></slot>
 
				<Search-overlay v-if="searchActive && hasSearchOverlay" :searchCache="searchCache" :initialProjects="projects" :searchQuery="searchQuery" :showTasks="showTasks" :url="searchUrl"
								:initialLimit="20" @projectClicked="searchProject" @taskClicked="searchTask" ref="searchOverlay" />
			</ion-content>
		</template>
 
		<Tracking-task-footer v-show="getActiveTask" @openTaskModal="doOpenTaskModal" @clicked="doOpenTaskModal()" ref="footer" />
	</ion-page>
</template>

<script>
import AHeader from '@/plugins/app/_layout/a-header.vue'
import TrackingTaskFooter from '@/plugins/lib@home/_components/tracking-task-footer.vue'

import CreateTaskModal from '@/plugins/lib@task/components/modals/modal-create-task.vue'
import ModalTask from '@/plugins/lib@task/components/modals/modal-task.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
	props: {
		title: {
			type: String
		},

		hasSearch: {
			type: Boolean,
			default: true
		},

		hasSearchOverlay: {
			type: Boolean,
			default: false
		},

		searchCache: {
			type: Object,
			default: () => ({})
		},

		searchUrl: { 
			type: String,
			default: "/search"
		},

		projects: {
			type: Array
		},

		openTaskModal: {
			type: Function
		},

		showTasks: {
			type: Boolean,
			default: true
		},

		lockYScrolling: {
			type: Boolean,
			default: true
		},

		backArrow: {
			type: Boolean,
			default: false
		},

		addAction: {
			type: Function
		}
	},

	components: { AHeader, TrackingTaskFooter },

	emits: ['searchTask', 'searchProject', 'searchToggled', 'refresh', 'contentScroll'],

	computed: {
		...mapGetters('projectsStore', ['getActiveTask']),

		finalAddAction() {
			return this.addAction === undefined ? this.openCreateTaskModal : this.addAction
		}
	},

	data() {
		return {
			searchQuery: "",
			searchActive: false,

			error: false
		}
	},

	async mounted() {
		await this._updateState()

		window.onkeydown = (e) => {
			const isSearchShortcut = this.hasSearch && (e.metaKey || e.ctrlKey) && e.shiftKey && e.key == 'F'
			if (isSearchShortcut) {
				e.preventDefault()
				this.$refs.header.showSearchbar()
			}
		}

		window.onfocus = async () => {
			await this._updateState()
			this.$emit('refresh')
		}
	},

	methods: {
		// ----- Setup -----
		...mapActions('projectsStore', ['updateTrackingState']),

		async _updateState() {
			await this.updateTrackingState()
				.then(() => this.error = false)
				.catch(err => console.error("State update failed:", this.error = err))
		},

		refresh() {
			try {
				window.location.reload()
			}
			catch (e) {
				this.$router.go(0)
			}
		},

		hideSearch() {
			this.$refs['header'].hideSearchbar()
		},

		toggleSearch(isActive, query) {
			if (this.searchActive = isActive)
				setTimeout(this.$refs.header.focusSearch, 600)

			if (!isActive && this.$route.query.search !== undefined)
				this.$router.push(this.$route.path)
			
			if (query)
				this.searchQuery = query

			this.$emit('searchToggled', this.searchQuery, isActive)
		},

		isHidden() {
			return this.$el?.classList.contains("ion-page-hidden")
		},

		searchFirst() {
			this.$refs.searchOverlay.clickFirstProject()
		},

		async searchTask(task, project) {
			this.hideSearch()

			this.$emit("searchTask", task, project)
		},

		async searchProject(project) {
			this.hideSearch()

			this.$emit("searchProject", project)
		},

		contentScrolled(ev) {
			this.$emit("contentScroll", ev);
		},
		
		async doOpenTaskModal(task, isUnassigned) {
			task ??= this.getActiveTask

			const isTaskModalOpen = this.openTaskModal && !(await this.openTaskModal(task, isUnassigned))
			if (isTaskModalOpen)
				return
			
			try {
				this.$modals.open(ModalTask, { task, isUnassigned });
			}
			catch (err) {
				console.error(err)
			}
		},

		async openCreateTaskModal() {
			this.$modals.open(CreateTaskModal)
		}
	},

	watch: {
		$route: {
			handler(route) {
				this.$nextTick(() => this.toggleSearch(route.query.search !== undefined, route.query.search))
			},
			immediate: true
		}
	}
}
</script>