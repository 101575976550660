<template>
	<div>
		<template v-for="(time, i) in formattedTimes" :key="time">
			<div v-if="formatDate(time.start) != formatDate(times[i - 1]?.start ?? 0)" class="time-line flex justify-between items-center sticky top-0 p-0.5 z-10 bg-medium text-white">
				<h4 class="m-0">{{ formatDay(time.start) }}</h4>
				<ion-icon v-if="allowAdd" class="text-[30px] <sm:text-[26px] cursor-pointer" :icon="$icons.addCircleOutline" @click="addTime(time.start)" />
			</div>
			<Time-stamp :timestamp="time" @edit="editTime(i, $event)" @delete="deleteTime(i, $event)" class="time mx-0.5" />
		</template>
	</div>
</template>

<script>
import TimeStamp from "./time-stamp.vue"

export default {
	components: { TimeStamp },

	props: {
		times: {
			type: Array,
			required: true
		},

		timeFormat: {
			type: Object
		},

		allowAdd: {
			type: Boolean,
			default: true
		},

		ranges: {
			type: Object
		}
	},

	emits: ["editTime", "deleteTime", "addTime"],

	computed: {
		formattedTimes() {
			return this.timeFormat ? this.times.map(time => ({ ...time, ...this.timeFormat })) : this.times
		}
	},

	methods: {
		formatDate: (dateStr) => new Date(dateStr ?? 0).format("yyyy-MM-dd"),
		formatDay(dateStr) {
			const formatted = this.formatDate(dateStr);
			return formatted + " | " +
					(formatted == this.ranges?.today.from ? "Today" : 
					formatted == this.ranges?.yesterday.from ? "Yesterday" : 
					new Date(dateStr ?? 0).format("EEEE"))
		},

		editTime(i, newTime) {
			this.$emit("editTime", i, newTime)
		},

		async deleteTime(i, time) {
			this.$emit("deleteTime", i, time)
		},

		async addTime(date) {
			this.$emit("addTime", date)
		}
	}
}
</script>

<style lang="sass" scoped>
.time:has(+.time-line)
	margin-bottom: 0 !important
	border: 0 !important
</style>