import { App } from 'vue'
import { open, currentlyOpened, modalController } from './models/modals'
import { Options } 	from './types'

export default {
	install: (app: App, options: Options) => {
		if (options?.disabled) return

		app.config.globalProperties.$modals = { open, currentlyOpened, ...modalController }
	}
}