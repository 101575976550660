// Remove accents and transform to lowercase
export function normalize(str) {
	return str?.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()
}

// Filter by name, used in search bars
export function filterByName(name, search) {
	return normalize(name)?.indexOf(normalize(search)) > -1
}

// Filter by array of tags
export function filterByArrayOfTags(arrayOfTags, search) {
	const matchingTags = arrayOfTags ? arrayOfTags.filter(tag => {
		return normalize(tag).substring(0, search.length) == search
	}) : []

	return matchingTags.length ? true : false
}

// Truncate text to n full words
export function truncateToNWords(str, max = 10) {
	const array = str.trim().split(' ')
	const ellipsis = array.length > max ? '...' : ''

	return array.slice(0, max).join(' ') + ellipsis
}
