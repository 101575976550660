import { alertController, AlertInput } from '@ionic/vue'

export { alertController }

export let currentlyOpened;

export function cancelBtn(onCancel) {
	return {
		text: 'Cancel',
		role: 'cancel',
		cssClass: 'popup-cancel-button',
		id: 'cancel-button',
		handler: () => onCancel?.(),
	}
}

export function confirmBtn(text, onConfirm) {
	return {
		text: text,
		role: 'confirm',
		id: 'confirm-button',
		cssClass: 'popup-confirm-button',
		handler: (alertData) => onConfirm?.(alertData),
	}
}

export async function alert(message, title = 'Alert', confirmBtnText = 'Ok', onConfirm?: Function, config = {}) {
	const popup = await alertController.create({
		header: title,
		message: message,
		cssClass: 'popup-alert',
		buttons: [
			confirmBtn(confirmBtnText, onConfirm),
		],
		...config,
	})
	popup.present()
	popup.onDidDismiss().then(() => currentlyOpened = null);

	currentlyOpened = popup
	return popup
}

export async function confirm(message, title = 'Confirm', confirmBtnText = 'Ok', onConfirm?: Function, onCancel?: Function, config = {}) {
	const popup = await alertController.create({
		header: title,
		message: message,
		cssClass: 'popup-confirm',
		buttons: [
			cancelBtn(onCancel),
			confirmBtn(confirmBtnText, onConfirm),
		],
		...config,
	})
	popup.present()
	popup.onDidDismiss().then(() => currentlyOpened = null);

	currentlyOpened = popup
	return popup
}

const defaultInput: AlertInput[] = [
	{ name: 'default', type: 'text', value: '', id: 'popup-prompt-input' }
]

export async function prompt(message, title = 'Prompt', inputs = defaultInput, confirmBtnText = 'Ok', onConfirm?: Function, onCancel?: Function, config = {}) {
	const popup = await alertController.create({
		cssClass: "popup-prompt",
		header: title,
		message: message,
		inputs: inputs,
		buttons: [
			cancelBtn(onCancel),
			confirmBtn(confirmBtnText, onConfirm),
		],
		...config,
	});
	popup.present()
	popup.onDidDismiss().then(() => currentlyOpened = null);

	currentlyOpened = popup
	return popup
}

export const async = {
	alert: async (message, title = 'Alert', confirmBtnText = 'Ok', config = {}) => 
				new Promise(resolve => alert(message, title, confirmBtnText, resolve, config)),

	confirm: async (message, title = 'Confirm', confirmBtnText = 'Ok', config = {}) => 
				new Promise(resolve => confirm(message, title, confirmBtnText, () => resolve(true), () => resolve(false), config)),

	prompt: async (message, title = 'Prompt', inputs = defaultInput, confirmBtnText = 'Ok', config = {}) => 
				new Promise(resolve => prompt(message, title, inputs, confirmBtnText, alertData => resolve(alertData), () => resolve(false), config))
}