export const isConfirmStopPopup = () => !localStorage.parse('disable-stop-tracking-alert')
export const setConfirmStopPopup = ev => localStorage['disable-stop-tracking-alert'] = !ev.detail.checked

export const isTaskSwitchPopup = () => !localStorage.parse('disable-switch-alert')
export const setTaskSwitchPopup = ev => localStorage['disable-switch-alert'] = !ev.detail.checked

export const isLocalStorageCache = () => !localStorage.parse('disable-local-cache')
export const setLocalStorageCache = ev => localStorage['disable-local-cache'] = !ev.detail.checked

export const getPreferedScreen = () => localStorage['prefered-route'] ?? 'home'
export const setPreferedScreen = ev => localStorage['prefered-route'] = ev.detail.value

export function getTheme() {
	const theme = localStorage['theme'] ?? 'light'
	document.querySelector('meta[name="color-scheme"]').setAttribute('content', theme)
	document.documentElement.setAttribute('theme', theme)
	return theme
}
export function setTheme(ev) {
	document.documentElement.setAttribute('theme', localStorage['theme'] = ev.detail.value)
	document.querySelector('meta[name="color-scheme"]').setAttribute('content', ev.detail.value)
}