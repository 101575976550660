<template>
	<ion-menu swipe-gesture="false" menu-id="app-menu" content-id="main-content" @ionDidOpen="$refs.nav?.getTotalDailyTime()" @ionDidClose="tab = 'main'">
		<ion-header>
			<ion-toolbar>
				<div class="menu-wrapper flex ion-justify-content-between ion-align-items-center">
					<img class="d-light" src="@/plugins/app/_assets/wg-light.png" />
					<img class="d-dark" src="@/plugins/app/_assets/wg-dark.png" />
					
					<ion-icon class="size-xl" :icon="$icons.close" @click="closeMenu()" />
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-list class="mt-1 flex flex-col" lines="none">
				<A-menu-navigation v-if="tab == 'main'" :timeInfo="timeInfo" ref="nav" />
				<A-settings v-else-if="tab == 'settings'" />
			</ion-list>
			<ion-item lines="none" @click="toggleSettings()">
				<ion-icon class="size-xl" slot="start" :icon="tab == 'main' ? $icons.settings : $icons.arrowBack" color="primary" />
				<ion-label class="mt-0 mb-0">{{tab == 'main' ? 'Settings' : 'Back'}}</ion-label>
			</ion-item>
		</ion-content>

		<ion-footer>
			<ion-toolbar>
				<ion-item lines="none" class="flex ion-align-items-center">
					<ion-icon class="size-xl" slot="start" :icon="$icons.logOut" size="large" color="primary" />
					<ion-label class="mt-0 mb-0" @click="signOut()">Log out</ion-label>
				</ion-item>
			</ion-toolbar>
		</ion-footer>
	</ion-menu>
</template>

<script>
import AMenuNavigation from './a-menu-navigation.vue'
import ASettings from './a-settings.vue'

import { menuController } from '@ionic/vue'
import { getTheme } from '../_config/settings'
import { googleLogout } from "vue3-google-login"

export default {
	data() {
		return {
			tab: 'main',
			timeInfo: {
				totalTimeToday: null,
				totalTimeThisMonth: null
			}
		}
	},

	components: { AMenuNavigation, ASettings },

	mounted() {
		getTheme()
	},

	methods: {
		toggleSettings() {
			this.tab = this.tab == 'main' ? 'settings' : 'main'
		},

		closeMenu() {
			menuController.close('app-menu')
		},

		async signOut() {
			await this.$wLoader.loadAction(async () => {
				try {
					googleLogout()

					await this.$store.dispatch('auth/logout')

					window.open(location.origin, '_self')
					this.closeMenu()
					localStorage.removeItem('projects-cache')
				} catch (error) {
					this.$toast.error(error, 'bottom')
				}
			}, 'Logging out...')
		}
	}
}
</script>

<style lang="sass" scoped>
ion-header
	ion-toolbar
		--background: transparent
		--border-color: var(--ion-color-light-tint)

		ion-icon
			cursor: pointer

ion-footer
	ion-toolbar
		--background: transparent
		--border-color: var(--ion-color-light-tint)

ion-item
	cursor: pointer

	ion-label
		font-weight: bold

h1
	font-weight: 500
	font-family: 'Coolvetica'
	font-size: 34px

img
	width: 64px

ion-list
	height: calc( 100% - 60px)
	min-height: 410px

.menu-wrapper
	padding: 6px 15px !important

ion-icon

	&:before
		content: ''
		position: absolute
		width: 100%
		height: 100%
</style>