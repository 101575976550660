<template>
	<ion-item-sliding class="bg-primary" ref="sliding" @ionDrag="onSlide">
		<ion-item-options side="start" @click="closeSliding()">
			<ion-item-option color="danger" @click="stopAndEdit(true)">Stop & edit</ion-item-option>
		</ion-item-options>

		<ion-item class="ion-no-padding" @click="clicked">
			<ion-footer>
				<ion-toolbar>
					<div class="flex ion-align-items-center ion-justify-content-between w-full">
						<div class="task-name-wrapper h-full">
							<p>
								Project:
								<b>{{ getActiveTask?.projectName }}</b>
							</p>
							<p>
								Task:
								<b>{{ getActiveTask?.name }}</b>
							</p>
						</div>

						<ion-toggle :checked="getActiveTask?.currentTime?.billable" @ionChange="updateTime($event.detail.checked, 'billable')" @click.stop class="mr-1 d-auto lg" />
						<ion-input :value="getActiveTask?.currentTime?.comment" @ionChange="updateTime($event.detail.value, 'comment')" @click.stop :debounce="600" placeholder="Comment your work..." class="comment d-auto lg ion-no-padding" />
						<div class="vertical-separator"></div>
						<p class="time text-center">{{ currentlyTrackedTime }}</p>
					</div>
				</ion-toolbar>
			</ion-footer>
		</ion-item>

		<ion-item-options @click="closeSliding()">
			<ion-item-option @click.stop class="d-auto sm hidden">
				<ion-toggle :checked="getActiveTask?.currentTime?.billable" @click.stop @ionChange="updateTime($event.detail.checked, 'billable')" class="mr-1" />
				<ion-input :value="getActiveTask?.currentTime?.comment" :debounce="600" @ionChange="updateTime($event.detail.value, 'comment')" placeholder="Comment..." class="comment sm" />
			</ion-item-option>
			<ion-item-option color="danger" @click="stopTrackingAction()">
				<ion-icon class="size-xxl" :icon="$icons.stopCircle" />
			</ion-item-option>
		</ion-item-options>
	</ion-item-sliding>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import axios from '@/plugins/w/axios/models/axios'
const api = {
	updateTask: async (taskId, newData) => await axios.patch_auth_data(`/times/${taskId}`, newData),
}

export default {
	computed: {
		...mapGetters('projectsStore', ['getActiveTask', 'getCurrentTrackedTime']),

		currentlyTrackedTime() {
			return Date.time(this.getCurrentTrackedTime).format()
		}
	},

	emits: ['openTaskModal', 'clicked'],

	data() {
		return {
			timeComment: ""
		}
	},

	methods: {
		...mapActions('projectsStore', ['stopTracking', 'stopTrackingAction']),

		async stopAndEdit(edit) {
			await this.stopTracking(edit ? (task => this.$emit('openTaskModal', task.task)) : null)
			this.closeSliding()
		},

		async onSlide(ev) {
			const ratio = Math.floor(ev.detail.ratio * 2)
			if (ratio == this.oldSlideRatio)
				return
			this.oldSlideRatio = ratio

			if (ratio == (window.innerWidth < 600 ? 3 : 5)) {
				return await this.stopAndEdit()
			}

			if (ratio == -4)
				await this.stopAndEdit(true)
		},

		async updateTime(value, valueName) {
			try {
				if (!this.getActiveTask?.currentTime || this.getActiveTask.currentTime[valueName] == value)
					return

				const updatedTime = await api.updateTask(this.getActiveTask.currentTime._id, { [valueName]: value })
				if (this.getActiveTask.currentTime)
					this.getActiveTask.currentTime[valueName] = updatedTime[valueName]
			}
			catch (err) {
				console.error(err)
			}
		},

		closeSliding() {
			this.$refs.sliding.$el.close()
		},

		clicked(ev) {
			this.$emit('clicked', ev)
		}
	}
}
</script>

<style lang="sass" scoped>
ion-toggle
	min-width: 45px !important
	height: 30px

	&::part(track)
		background: rgba(255, 255, 255, 0.69)

	&::part(handle) 
		display: flex
		justify-content: center
		align-items: center
		background: var(--ion-color-primary)

		&::after 
			content: '$'
			color: rgba(255, 255, 255, 0.3)
			font-size: 18px

	&.toggle-checked
		&::part(track)
			background: white

		&::part(handle) 
			&::after 
				color: white

ion-item-options, ion-item-option
	border-width: 0 !important

.d-auto
	@media only screen and (max-width: 600px)
		&.lg
			display: none !important

		&.sm
			display: block !important

.comment
	min-width: 250px
	color: white

	&.sm
		min-width: 0 !important
		width: 150px

ion-item 
	--inner-padding-end: 0
	--inner-border-width: 0

	ion-footer
		ion-toolbar
			--background: var(--ion-color-primary)
			--min-height: 40px
			--height: fit-content
			--padding-start: 10px
			--padding-end: 10px
			--padding-top: 10px
			--padding-bottom: 10px
			cursor: pointer

		.task-name-wrapper
			flex: 1 1 100%

			p
				display: -webkit-box
				-webkit-box-orient: vertical
				overflow: hidden
				-webkit-line-clamp: 2
				text-overflow: ellipsis

		.vertical-separator
			height: 30px
			width: 0px
			border-right: 1px solid white
			margin: 0 15px

		p
			margin: 0
			color: white
			font-size: 14px

		ion-icon
			color: white
			flex: 0 0 auto

		.time
			width: 65px
			flex-shrink: 0
</style>