import axios from '@/plugins/w/axios/models/axios'
const api = {
	getUser: async () => await axios.get_auth_data(`/currentUser`),
	getCurrentTime: async (ofTaskId) => await axios.get_auth_data(`/tasks/${ofTaskId}/currentTime`),
	startTracking: async (taskId) => await axios.post_auth_data(`/tasks/${taskId}/startTracking`),
	stopTracking: async (taskId) => await axios.post_auth_data(`/tasks/${taskId}/stopTracking`)
}

export default {
	namespaced: true,
	state() {
		return {
			activeTask: null,
			currentTrackedTime: 0,
			user: null
		}
	},

	mutations: {
		updateActiveTask: (state, task) => state.activeTask = task,
		updateCurrentTrackedTime: (state, currentTrackedTime) => state.currentTrackedTime = currentTrackedTime,
		updateUser: (state, user) => state.user = user
	},

	getters: {
		getActiveTask: (state) => state.activeTask,
		getCurrentTrackedTime: (state) => state.currentTrackedTime,
		getUser: (state) => state.user ?? api.getUser().then(user => state.user = user)
	},

	actions: {
		/////////////////////////////////////////////////////////////////////////////////////

		async updateTrackingState({ commit, dispatch }, taskData) {
			let task = taskData?.task ? taskData.task : taskData;
			if (!task) {
				const user = await api.getUser()
				commit('updateUser', user)
				
				task = user.currentTask
			}

			commit('updateActiveTask', task)
			if (!task)
				return
			
			const currentTime = taskData?.start ? taskData : await api.getCurrentTime(task._id)
			dispatch('_startTracker', currentTime)
		},

		_startTracker({ state, commit, dispatch }, currentTime) {
			const start = new Date(currentTime.start), realTime = new Date()
			commit('updateCurrentTrackedTime', Math.max((realTime.getTime() - start.getTime()) / 1000, 0))
			state.activeTask.currentTime = currentTime
			
			clearInterval(this.currentInterval)
			this.lastTime = Date.now()
			this.currentInterval = setInterval(() => {
				commit('updateCurrentTrackedTime', state.currentTrackedTime + (Date.now() - this.lastTime) / 1000)
				dispatch('_updateTitleTaskIndicator')

				this.lastTime = Date.now()
			}, 500)
		},

		_updateTitleTaskIndicator({ state }) {
			document.title = `(${(Date as any).time(state.currentTrackedTime).format()}) Teamgrid App`
			if (!state.activeTask) {
				document.title = 'Teamgrid App'
				clearInterval(this.currentInterval)
			}
		},

		/////////////////////////////////////////////////////////////////////////////////////

		async startTracking({ state, dispatch }, data) {
			if (this.pendingTask)
				return

			if (state.activeTask)
				return await dispatch('_showSwitchTrackingAlert', data)

			try { 
				const newTask = await (this.pendingTask = api.startTracking(data._id))
				this.$app.$eventBus.emit('updateTask', newTask)

				dispatch('updateTrackingState', newTask)
			} catch(error) {
				this.$app.$toast.error(error)
				console.error(error)
			} finally {
				this.pendingTask = null
			}
		},

		async stopTracking({ state, dispatch }, callback) {
			if (!state.activeTask)
				return

			if (localStorage["disable-stop-tracking-alert"] == 'true') {
				const task = await dispatch('stopTrackingAction')
				return callback?.(task)
			}

			const message = `<b>Do you want to stop tracking:</b><br><br><i>${state.activeTask.name}</i>`
			await this.$app.$popups.confirm(message, 'Stop tracking!', 'Stop', async () => {
				const task = await dispatch('stopTrackingAction')
				callback?.(task)
			})
		},

		async stopTrackingAction({ state, commit }) {
			if (this.pendingStop)
				return

			try {
				const stoppedTask = await (this.pendingStop = api.stopTracking(state.activeTask._id))
				this.$app.$eventBus.emit('updateTask', stoppedTask)

				state.activeTask.totalTime += stoppedTask.duration
				commit('updateActiveTask', null)
				return stoppedTask
			} catch(error) {				
				this.$app.$toast.error(error)
				console.error(error)
			}
			finally {
				this.pendingStop = null
			}
		},

		async _showSwitchTrackingAlert({ state, dispatch }, data) {
			if (localStorage["disable-switch-alert"] == 'true') {
				if (await dispatch('stopTrackingAction'))
					await dispatch('startTracking', data)
				return
			}

			const message = `<br>Do you want to <u>stop</u> tracking:<br><b><i>${state.activeTask.name}</i></b><br><br> and <u>start</u> tracking<br><b><i>${data.name}</i></b>`
			await this.$app.$popups.confirm(message, 'Stop tracking!', 'Yes', async () => {
				if (await dispatch('stopTrackingAction'))
					await dispatch('startTracking', data)
			}, null, {
				inputs: [
					{
						name: "disable-switch-alert",
						label: "Dont show again!",
						type: "checkbox",
						handler: (value) => localStorage["disable-switch-alert"] = value.checked
					}
				]
			})
		},
	}
}