<template>
	<ion-header>
		<ion-toolbar>
			<div class="flex ion-justify-content-between ion-align-items-center w-full">
				<div class="flex ion-align-items-center">
					<ion-icon v-if="!backArrow" class="size-xxl" :icon="$icons.menu" @click="openMenu()" />
					<ion-icon v-else class="size-xxl" :icon="$icons.chevronBack" @click="goBack()" />
					<ion-icon v-if="addAction" class="size-xxl ml-1" :icon="$icons.addCircleOutline" @click="addAction()" />
				</div>

				<ion-title>{{title}}</ion-title>

				<template v-if="hasSearch">
					<ion-icon v-if="!searchActive" class="size-xl" :icon="$icons.searchSharp" @click="showSearchbar(true)" />

					<p v-else class="button" @click="hideSearchbar()">Cancel</p>

					<ion-searchbar :value="modelValue" placeholder="Project or Task name..."
						:class="{'search-active' : searchActive}" :debounce="400" @ionChange="updateVModel" @keydown.esc="hideSearchbar()"
						@keyup.enter="enterPressed()" ref="searchbar" />
				</template>
			</div>
		</ion-toolbar>
	</ion-header>
</template>

<script>
import { menuController } from '@ionic/vue'

export default {
	props: {
		title: {
			type: String,
			default: "Home"
		},

		modelValue: {
			type: String,
			default: ""
		},

		hasSearch: {
			type: Boolean,
			default: true
		},

		searchActive: {
			type: Boolean,
			default: true
		},

		backArrow: {
			type: Boolean,
			default: false
		},

		addAction: {
			type: Function
		}
	},

	emits: ['toggleSearch', 'enterPressed', "update:modelValue"],

	mounted() {
		for (var i = 1; this.searchActive && i <= 2; i++)
			setTimeout(this.focusSearch, i * 600)
	},

	methods: {
		openMenu() {
			menuController.open('app-menu')
		},

		focusSearch() {
			const input = this.$refs.searchbar.$el.querySelector('input')
			input.focus()
			return input
		},

		showSearchbar(emit) {
			if (emit)
				this.$emit('toggleSearch', true)

			setTimeout(() => this.focusSearch().select(), 600)
		},

		updateVModel(ev) {
			this.$emit('update:modelValue', ev.target.value)
		},

		hideSearchbar() {
			this.$emit('toggleSearch', false)
		},

		enterPressed() {
			this.$emit('enterPressed')
		},

		goBack() {
			this.$router.go(-1)
		}
	}
}
</script>

<style lang="sass" scoped>
ion-header
	ion-toolbar
		--background: var(--ion-color-primary) !important
		--min-height: 50px
		--padding-start: 15px
		--padding-end: 15px

		ion-title, p, ion-icon
			color: white

		p
			cursor: pointer

		ion-icon
			cursor: pointer

			&:before
				content: ''
				position: absolute
				width: 100%
				height: 100%

ion-searchbar
	position: absolute
	top: calc( 50% - 18px )
	right: 70px
	width: 0
	height: 36px
	z-index: 99 !important
	margin: 0
	padding: 0
	overflow: hidden
	--background: var(--ion-color-light)
	--border-radius: 3px
	transition: .3s

	&::v-deep
		.searchbar-input
			font-size: 13px !important

	&.search-active
		width: calc( 100% - 85px ) !important
		
</style>