<template>
	<div class="search-wrapper">
		<div v-if="searchResults.length" class="container w-full mt-2">
			<Project-card v-for="project in searchResults" v-bind="project" :key="project._id" @click="onProjectClicked(project)">
				<Task-card v-for="task in getTasks(project.tasks, null, showTasks)" :task="task" :project="project" :key="task._id" :hasModal="false" :isCompletable="false"
					@clicked="onTaskClicked(task, project)" class="mt-1 mb-1 mr-1 ml-1" />
			</Project-card>
		</div>
		<h3 v-else-if="activeSearches <= 0" class="absolute-middle text-center mt-0 mb-0">Nenašli sa žiadne projekty ani tasky</h3>

		<div v-if="activeSearches > 0" :class="{'h-full': !searchResults.length}" class="flex flex-col ion-align-items-center ion-justify-content-center loading">
			<ion-spinner name="crescent"></ion-spinner>
			<p>Loading...</p>
		</div>
	</div>
</template>

<script>
import TaskCard from '@/plugins/lib@task/task-card.vue'
import ProjectCard from "./components/search-project-card.vue";

import { search, normalize, getTasks } from "./search-utils"

import axios from '@/plugins/w/axios/models/axios'
const api = {
	getSearchData: async (query, url = '/search', limit = undefined) => await axios.post_auth_data(url, { query, limit })
}

export default {
	components: { ProjectCard, TaskCard },

	props: {
		initialProjects: {
			type: Array,
			default() {
				return []
			}
		},
		searchQuery: {
			type: String,
			default: ''
		},
		url: {
			type: String,
			default: '/search'
		},

		searchCache: {
			type: Object,
			default() {
				return {}
			}
		},
		searchOnOpen: {
			type: Boolean,
			default: true
		},
		showTasks: {
			type: Boolean,
			default: true
		},
		initialLimit: {
			type: Number
		}
	},

	emits: ['projectClicked', 'taskClicked'],

	data() {
		return {
			searchResults: [],
			activeSearches: 0,
		}
	},

	async mounted() {
		if (this.searchOnOpen || !this.searchResults.length)
			return await this._search();
	},

	watch: {
		async searchQuery() {
			await this._search()
		}
	},

	methods: {
		clickFirstProject() {
			this.onProjectClicked(this.searchResults[0])
		},

		getTasks,

		async _search() {
			const query = normalize(this.searchQuery)
			if (!query.length && this.initialProjects?.length)
				return this.searchResults = this.initialProjects

			this.searchResults = this.searchCache?.[query] || search(query, this.initialProjects, this.showTasks)

			try {
				this.activeSearches++

				const limit = query?.length ? undefined : this.initialLimit
				this.searchResults = await api.getSearchData(query, this.url, limit)
				if (this.searchCache)
					this.searchCache[query] = this.searchResults
			}
			catch (err) {
				console.error(err)
			}
			finally {
				this.activeSearches--
			}
		},

		onProjectClicked(project) {
			this.$emit('projectClicked', project)
		},

		onTaskClicked(task, project) {
			this.$emit('taskClicked', task, project)
		}
	}
}
</script>

<style lang="sass" scoped>
.search-wrapper
	width: 100%
	height: 100%
	position: absolute
	top: 0
	left: 0
	z-index: 100
	background: var(--ion-color-light)
	animation: searchSuggestionEnter 300ms ease 0s 1 normal forwards
	overflow-x: hidden
	overflow-y: auto

	.container
		max-width: 1440px
		margin: auto

	.loading
		height: 200px
		transform: scale(1.5)
</style>