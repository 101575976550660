<template>
	<Z-modal :hasEditableName="!isUnassigned" :name="task.name" @editName="editNameAlertPrompt()">
		<template #header>
			<div v-if="!isUnassigned" class="pin-icon">
				<img v-if="task.pinned" src="../../_assets/push-pin.png" alt="img not found" @click="togglePinTask()" class="close-modal-icon">
				<img v-else src="../../_assets/push-pin-outline.png" alt="img not found" @click="togglePinTask()" class="close-modal-icon">
			</div>
		</template>

		<div class="content w-full h-full" @scroll="onScroll" ref="content">
			<h2 class="mt-1">Project:</h2>
			<ion-toolbar class="project-info flex ion-align-items-center mb-3" @click="openProjectsSelectModal()">
				<img class="project-image mr-1" slot="start" src="@/plugins/app/_assets/wzo.jpg" />

				<h4 v-if="projectChoice">{{ projectChoice?.name ?? projectChoice.contactName }}</h4>

				<ion-icon v-if="!isUnassigned" :icon="$icons.listSharp" class="project-select-icon" slot="end" />
			</ion-toolbar>

			<div class="flex ion-justify-content-between times-display">
				<div class="flex ion-align-items-center">
					<ion-icon class="size-xxl" :icon="$icons.timer" />
					<h3 class="ml-1 mt-0 mb-0 text-[18px]">{{ Date.time(totalTime).format("k'h' mm'm'") }}</h3>
				</div>
				<div v-if="!isUnassigned" class="flex ion-align-items-center" v-memo="[task.plannedTime]">
					<ion-icon class="size-xxl" :icon="$icons.alarm" />
					<ion-input :value="plannedTimeInput" class="mt-0 mb-0 ion-no-padding text-[18px]" @keydown.enter="confirmPlannedTime" @ionBlur="plannedTimeInput = $event.target.value" ref="planned-input"/>
				</div>
			</div>
			<ion-progress-bar v-if="task.plannedTime" :value="progress" :color="progress > 1 ? 'warning' : 'success'" />

			<template v-if="!isUnassigned">
				<div class="flex ion-align-items-center mt-2">
					<div class="categories relative w-full">
						<ion-select @click="getCategories" :value="task.category?._id" :key="task.categories" placeholder="Select category" interface="popover" @ionChange="updateCategory" ref="categories">
							<ion-select-option v-if="!task.categories?.length" disabled>No categories found!</ion-select-option>
							<ion-select-option v-else v-for="category in task.categories" :key="category" :value="category._id">{{category.name}}</ion-select-option>
						</ion-select>
						<ion-icon class="size-sm absolute clear-icon" @click="clearCategory" :icon="$icons.close" />
					</div>
					<ion-icon v-if="times?.length > 1" @click="switchBillableForAll()" :icon="$icons.logoUsd" :class="{ active: task.globalBillable }" class="ml-1 billable p-[6px]" />
				</div>
				
				<div class="flex w-100 ion-justify-content-between mt-2">
					<h2>All time records:</h2>
					<p class="add-button" @click="openAddTimeModal()">Add time</p>
				</div>

				<div>
					<Z-timeline :times="times" :timeFormat="{ task: null }" @addTime="openAddTimeModal" @editTime="editTime" @deleteTime="deleteTime" class="w-full mb-1" v-memo=[times] />
					<ion-skeleton-text v-if="pendingTimes" animated />
				</div>
			</template>
		</div>

		<template #footer>
			<ion-button v-if="!isUnassigned && isCompletable && !task.completed && !isActive" 
				@click="toggleCompleteTask()" class="w-full complete-task-button mb-1" color="light">
				<ion-label>Complete task</ion-label>
			</ion-button>

			<ion-button v-if="task.completed" @click="toggleCompleteTask()" class="w-full"
				expand="block" color="secondary">Reopen task</ion-button>
			<ion-button v-else-if="isActive" class="w-full" @click="setTracking(false)">
				<ion-icon class="size-l mr-1" :icon="$icons.stopCircle" />
				{{ Date.time(getCurrentTrackedTime).format() }}
			</ion-button>
			<ion-button v-else class="w-full" @click="setTracking(true)">Start tracking {{isUnassigned ? " and claim" : ""}}</ion-button>
		</template>
	</Z-modal>
</template>

<script>
import TaskProjectSelect from "./modal-task-project-select";
import ZTimeline from "../z-timeline.vue";

import openTimestampModal from "./modal-time-stamp"

import { mapMutations, mapGetters, mapActions } from "vuex";

import axios from '@/plugins/w/axios/models/axios'

const api = {
	pinTask: async (taskId, isPinned) => await axios.post_auth_data(`/tasks/${taskId}/${isPinned ? 'pin' : 'unpin'}`),
	loadTimes: async (taskId, page = 1, limit = 25) => await axios.get_auth(`/tasks/${taskId}/times`, { page, limit }),
	updateTask: async (taskId, newData) => await axios.patch_auth_data(`/tasks/${taskId}`, newData),
	addTime: async (taskId, projectId, start, end, comment, billable) => await axios.post_auth_data("/times", { taskId, projectId, start, end, comment, billable }),
	getProject: async (projectId) => await axios.get_auth_data(`/projects/${projectId}`),
	getCategories: async (projectId) => await axios.get_auth_data(`/projects/${projectId}/categories`),
	switchBillableForAll: async (taskId, billable) => await axios.put_auth_data(`/tasks/${taskId}/changeBillable`, { billable })
}

export default {
	components: { ZTimeline },
	
	props: {
		task: {
			type: Object,
			required: true,
		},

		project: {
			type: Object
		},

		isUnassigned: {
			type: Boolean,
			default: false
		},

		isCompletable: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			times: [],
			pendingTimes: null,

			projectChoice: null,

			taskPinChanged: false
		}
	},

	async mounted() {
		this.projectChoice = this.project ?? await api.getProject(this.task.projectId);
		
		this.loadTimes(this.task.timesCache || [])
		this.getCategories()
	},

	beforeUnmount() {
		this.task.timesCache = this.times
		if (this.taskPinChanged) this.$eventBus.emit(this.task.pinned ? 'reloadPinnedTasks' : 'unpinned', this.task)
	},

	computed: {
		...mapGetters("projectsStore", ["getCurrentTrackedTime", "getActiveTask"]),

		plannedTimeInput: {
			get() {
				return Date.time(this.task.plannedTime ?? 0).format("k'h' mm'm'")
			},

			async set(value) {
				try {
					value = value.trim().toLowerCase()
					if (value.endsWith("h"))
						value += " 0"
					else if (value.search(/\:| +/) < 0)
						value = "0 " + value
					
					const plannedTime = Date.time(value.replaceAll(/h|m/g, '')).add({ hours: 1 }).getTime() / 1000
					if (this.task.plannedTime == plannedTime || isNaN(plannedTime)) {
						//Wierd but convenient way to force rerender of planned time input...
						this.task.plannedTime++
						return this.task.plannedTime--
					}

					const updatedTask = await api.updateTask(this.task._id, { plannedTime })
					this.task.plannedTime = updatedTask.plannedTime
				}
				catch (err) {
					console.error(err)
				}
			}
		},

		totalTime() {
			return this.isActive ? this.task.totalTime + this.getCurrentTrackedTime : this.task.totalTime
		},

		progress() {
			return this.totalTime / this.task.plannedTime
		},

		isActive() {
			return this.getActiveTask?._id == this.task._id
		}
	},

	methods: {
		...mapMutations("projectsStore", ["taskProjectChanged"]),

		...mapActions('projectsStore', ['startTracking', 'stopTrackingAction']),

		async onScroll(ev) {
			if (this.$refs.content.scrollTop / (this.$refs.content.scrollHeight - this.$refs.content.clientHeight) >= 1)
				await this.loadTimes()
		},

		confirmPlannedTime() {
			this.$refs['planned-input']?.$el.setBlur()
		},

		// Pin Task

		async togglePinTask() {
			this.task.pinned = !this.task.pinned
			try {
				this.taskPinChanged = !this.taskPinChanged
				await api.pinTask(this.task._id, this.task.pinned)
				this.$eventBus.emit('updateTask', this.task)
			} catch (err) {
				this.$toast.error(`Task ${this.task.pinned ? 'pin' : 'unpin'} unsuccessfull`)
				this.task.pinned = !this.task.pinned
				console.error(err)
			}
		},

		// Setup

		async loadTimes(initialLoad) {
			try {
				const currentPage = Math.ceil(this.times.length / 25) + 1
				if (this.isUnassigned || this.pendingTimes || currentPage > this.lastTimesPage)
					return

				if (initialLoad)
					this.times = initialLoad

				const { data, meta } = await (this.pendingTimes = api.loadTimes(this.task._id, currentPage, 25))
				this.pendingTimes = null
				
				if (initialLoad) 
					this.times = data
				else
					this.times.push(...data)

				this.lastTimesPage ??= meta.last_page
			}
			catch (err) {
				this.pendingTimes = null
				console.error(err);
			}
		},

		editTime(i, newTime) {
			const timeDiff = newTime.duration - this.times[i].duration
			this.times[i] = newTime;
			this.task.totalTime += timeDiff
		},

		deleteTime(i, time) {
			this.times.splice(i, 1);

			this.task.totalTime -= time.duration
		},

		// projects selecting

		async openProjectsSelectModal() {
			if (this.isUnassigned)
				return

			const projectModal = await this.$modals.open(TaskProjectSelect, null, { handle: false })

			const { data } = await projectModal.onDidDismiss()
			if (data)
				this._onProjectSelected(data)
		},

		async _onProjectSelected(projectSelection) {
			if (this.task.projectId == projectSelection._id) return;

			try {
				const updatedTask = await api.updateTask(this.task._id, { projectId: projectSelection._id })
				this.projectChoice = projectSelection

				this.$eventBus.emit("removeTask", this.task)
				this.task.projectId = this.projectChoice._id
				if (this.isActive)
					this.getActiveTask.projectId = this.projectChoice._id

				this.$eventBus.emit("updateTask", updatedTask)

			} catch (error) {
				console.error(error);
			}
		},

		// Tracking

		async setTracking(start) {
			if (start)
				await this.startTracking(this.task)
			else
				await this.stopTrackingAction()
			
			this.closeModal();
		},

		// Name Edit

		async editNameAlertPrompt() {
			const inputs = [
				{ name: 'name', type: 'text', value: this.task.name, id: 'popup-prompt-input' }
			]

			this.$popups.prompt("", "Edit name", inputs, "Save", date => this._saveNameEdit(date.name.trim()))
		},

		async _saveNameEdit(name) {
			if (name.length < 1 || this.task.name == name)
				return

			try {	
				await api.updateTask(this.task._id, { name });
				this.task.name = name;
				this.$eventBus.emit("updateTask", this.task)
			} catch (err) {
				console.error(err)
			}
		},

		// Time Edit

		async openAddTimeModal(date) {
			const newTime = await openTimestampModal({ date, billable: this.projectChoice.is_billable || this.task.globalBillable }, 'Add time', null)
			if (newTime)
				await this._postTimeEntry(newTime.start, newTime.end, newTime.comment, newTime.billable)
		},

		async _postTimeEntry(start, end, comment, billable) {
			await this.$wLoader.loadAction(async () => {
				try {
					const newTime = await api.addTime(this.task._id, this.task.projectId, start, end, comment, billable)
					this.task.totalTime += newTime.duration
					this.times.unshift(newTime)
				}
				catch (err) {
					console.error(err)
				}
			}, "Adding time entry...")
		},

		async toggleCompleteTask() {
			try {
				const updatedTask = await api.updateTask(this.task._id, { completed: !this.task.completed })
				this.$eventBus.emit('updateTask', updatedTask)

				this.closeModal()
			}
			catch (err) {
				console.error(err)
			}
		},

		async updateCategory(ev) {
			const oldValue = this.task.category
			try {
				const updatedTask = await api.updateTask(this.task._id, { categoryId: ev.target.value })
				this.task.category = updatedTask.category
				this.$eventBus.emit('updatedCategory', { task: this.task, oldValue, newValue: this.task.category })
			}
			catch (err) {
				console.error(err)
			}
		},

		async switchBillableForAll() {
			try {
				const confirm = await this.$popups.async.confirm(`This will switch billability for all times in this task to <b>${this.task.globalBillable ? "unbillable" : "billable"}</b>!`, "Do you want to switch?", "Yes")
				if (!confirm)
					return

				await api.switchBillableForAll(this.task._id, !this.task.globalBillable)

				this.task.globalBillable ^= true
				this.times.forEach(time => time.billable = this.task.globalBillable);
			}
			catch (e) {
				console.error(e)
			}
		},

		async getCategories() {
			try {
				if (this.task.categories?.length)
					return

				if (this.task.category)
					this.task.categories = [this.task.category]
				this.task.categories = await api.getCategories(this.task.projectId)
			}
			catch (err) {
				console.error(err)
			}
		},

		clearCategory() {
			this.updateCategory({target:{value:0}})
		},

		closeModal() {
			this.$modals.dismiss()
		}
	},
};
</script>

<style lang="sass" scoped>
@import '../../_theme/billable-btn.sass'

[theme="dark"]
	.pin-icon
		img
			filter: invert(1)

.content
	box-shadow: inset 0 6px 8px -8px rgb(0 0 0 / 30%)
	padding: 0 13px !important
	overflow-x: hidden
	overflow-y: auto

.times-display
	margin-bottom: 5px

	ion-input
		width: 85px
		font-size: 23px

		&:not(.has-focus)
			border: none !important

.add-button
	margin: 0
	color: var(--ion-color-primary)
	font-weight: bold
	font-size: 16px
	cursor: pointer

h2
	font-size: 18px
	color: var(--ion-color-medium)
	margin-top: 0
	line-height: 18px

h3
	line-height: 23px

h4
	margin: 0

ion-select
	border: solid 1px var(--ion-color-medium-tint)
	border-radius: 5px

	&::part(icon)
		position: relative
		right: 29px

.project-image
	width: 55px
	height: 55px
	object-fit: cover
	border-radius: 100px
	border: 1px solid var(--ion-color-medium-tint)

.project-info
	--background: transparent !important
	margin: 0

	.project-select-icon
		font-size: 25px
		color: var(--ion-color-medium)

.customer
	color: var(--ion-color-medium-tint)

.complete-task-button
	--color: #0449FF !important
	min-height: 40px
	margin-bottom: 0px !important
	--background: #ffffff !important
	--ion-color-base: none !important
	--border-style: solid
	--border-color: #0449FF
	--border-width: 1.5px

	ion-label
		color: #0449FF !important

ion-icon
	cursor: pointer

	&:before
		content: ''
		position: absolute
		width: 100%
		height: 100%

.pin-icon
	img
		height: 25px
		width: auto
		cursor: pointer

.clear-icon
	top: 29%
	right: 14px
	z-index: 2
	color: #ababab
</style>