<template>
	<template v-for="(tab, i) in tabs" :key="i"> 
		<div v-if="tab == '-'" class="horizontal-separator"></div>
		<ion-item v-else :routerLink="`/${tab.route}`" :href="tab.href" :class="{'border-primary': $route.fullPath == `/${tab.route}`}" button detail="false" class="pl-0.5" @click="close()" >
			<ion-icon class="size-xl" slot="start" :icon="$icons[tab.icon ?? tab.route]" color="primary" />
			<div class="flex ion-align-items-center flex-wrap">
				<ion-label class="mt-1 mb-1 mr-1">{{ tab.name }}</ion-label>
				<div v-if="tab.time" class="time-tracking-wrapper">
					<p v-if="tab.time() != null" class="ion-no-margin">{{ tab.time() }}</p>
					<ion-skeleton-text v-else animated></ion-skeleton-text>
				</div>
			</div>
		</ion-item>
	</template>
</template>

<script>
import { menuController } from '@ionic/vue'

import axios from '@/plugins/w/axios/models/axios'
const api = {
	getTotalTimeToday: async () => await axios.get_auth_data('/timeStatistics')
}

export default {
	props: {
		timeInfo: {
			type: Object,
			default() {
				return {
					totalTimeToday: null,
					totalTimeThisMonth: null
				}
			}
		}
	},

	data() {
		return {
			tabs: [
				{name: "My projects", route: "home"},
				{name: "Search unassigned tasks", route: "unassigned?search", icon: "searchCircle"},
				{name: "My Tasks", route: "mytasks", icon: "albums"},
				{name: "Projects", route: "projects", icon: "apps"},
				{name: "Analytics", route: "analytics"},
				"-",
				{name: "Report bug or new feature", href: "https://www.notion.so/wezeo/76f7cf6a8f184dd0b8a5abb419fae061?v=0eec7d6246934c978171c2ab7164d986", icon: "bug"},
				"-",
				{name: "Total time today:", route: "analytics?range=today", icon: "timer", time: () => this.totalTimeToday },
				{name: "This month total:", route: "analytics?range=this%20month", icon: "calendar", time: () => this.totalTimeThisMonth },
			],
		}
	},

	computed: {
		totalTimeToday() {
			return Date.time(this.timeInfo.totalTimeToday).format("k'h' mm'm'")
		},

		totalTimeThisMonth() {
			return Date.time(this.timeInfo.totalTimeThisMonth).format("k'h' mm'm'")
		}
	},

	methods: {
		async getTotalDailyTime() {
			try {
				const { totalTimeToday, totalTimeThisMonth } = await api.getTotalTimeToday()
				this.timeInfo.totalTimeToday = totalTimeToday
				this.timeInfo.totalTimeThisMonth = totalTimeThisMonth
			}
			catch (err) {
				console.error(err)
			}
		},

		close() {
			menuController.close('app-menu')
		}
	}
}
</script>

<style lang="sass" scoped>
.horizontal-separator
	width: 100%
	border-bottom: 1px solid var(--ion-color-medium-tint)
	margin: 3px 0 !important

ion-item
	border-left: 5px solid transparent
	cursor: pointer
	--min-height: fit-content
	--padding-start: 10px

	ion-label
		font-weight: bold

	.time-tracking-wrapper
		min-width: 60px
		padding: 2px 5px
		margin: 0

		border: 1px solid var(--ion-color-medium-tint)
		border-radius: 4px
</style>