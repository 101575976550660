<template>
	<ion-app>
		<A-side-menu />
		<ion-router-outlet class="bg-light" id="main-content" />
	</ion-app>
</template>

<script>
import ASideMenu from '@/plugins/app/_layout/a-side-menu.vue'

export default {
	components: {
		ASideMenu
	}
}
</script>