import { createApp } from 'vue'

/* Ionic css */
import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Ionic css aditionals */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme */
import '@/plugins/app/_theme/ion-colors.scss'
import '@/plugins/app/_theme/index.sass'
import '@/plugins/app/_theme/tailwind.css'

/* Auth token refresh */
import '@/plugins/w/auth/refreshExpired'

/* Global date utility */
import '@/plugins/lib@util/datetime-utility/global-time-utility'
import '@/plugins/lib@util/local-storage'

import './registerServiceWorker'

/* App essentials */
import App from '@/plugins/app/_root/App.vue'
import { IonicVue } from '@ionic/vue'
const app = createApp(App).use(IonicVue, {swipeBackEnabled: false})

import router from '@/plugins/app/_router'
app.use(router)

import store from '@/plugins/app/_config/store'
(store as any).$app = app.config.globalProperties
app.use(store)

import w from '@/plugins/w/w'
app.use(w)

import GAuth from 'vue3-google-login'
app.use(GAuth, { clientId: process.env.VUE_APP_GAUTH_ID, scope: 'email profile openid' })

/* App Utils */
import ionicons from '@/plugins/lib@util/ionicons/index'
import popups from '@/plugins/lib@util/ion-popups/index'
import modals from '@/plugins/lib@util/ion-modals/index'
app.use(ionicons)
app.use(popups)
app.use(modals)

/* W loadables utility */
import wLoader from '@/plugins/w/loader'
import WLoadable from '@/plugins/w/loadable/w-loadable.vue'
app.config.globalProperties.$wLoader = wLoader
app.component('WLoadable', WLoadable)

/* Global registration of popular app components */
import ZModal from '@/plugins/app/_layout/z-modal.vue'
import ZScreen from '@/plugins/app/_layout/z-screen.vue'
import SearchOverlay from '@/plugins/lib@search/search-overlay.vue'
app.component('ZModal', ZModal)
app.component('ZScreen', ZScreen)
app.component('SearchOverlay', SearchOverlay)

/* Toutch events */
import Vue3TouchEvents from 'vue3-touch-events'
app.use(Vue3TouchEvents, {touchHoldTolerance: 1})

/* Timezone header */
import axios from 'axios'
axios.defaults.headers.common.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

/* App version */
app.config.globalProperties.$appVersion = require('../package.json').version

router.isReady().then(() => {
	try {
		app.mount('#app')
		screen.orientation.lock('portrait')
	}
	catch (err) {
		console.error(err)
	}
})