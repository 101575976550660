<template>
	<div class="flex flex-wrap justify-evenly <sm:justify-between items-center border-0 border-b border-medium border-solid mb-1 p-[6px] cursor-pointer" @click="openEditTimeModal()">
		<template v-if="taskName">
			<div class="w-full md:w-[300px] lg:w-[350px] <md:mb-1 order-1">
				<p class="line-clamp-2 font-bold m-0 <md:mr-auto" @click.stop="openModalTask()">{{ taskName }}</p>
				<p v-if="timestamp?.comment" class="truncate m-0 mt-[2px] text-medium">{{ timestamp.comment }}</p>
			</div>

			<div class="sm:hidden basis-full h-0 order-2"></div>

			<div class="<md:hidden min-h-[45px] w-[1px] bg-medium order-2"></div>
		</template>

		<template v-if="projectName">
			<div class="text-center w-[125px] order-2" @click.stop="openRedirects($event, 1)">
				<p class="m-0 font-bold mb-[2px]">Project</p>
				<p class="m-0 text-medium">{{ projectName }}</p>
			</div>
			<ion-popover :event="redirectsClickEvent" :is-open="isRedirectsOpen" dismiss-on-select @didDismiss="isRedirectsOpen = false">
				<ion-item button detail="false" :routerLink="`/home?search=${projectName}`">
					<ion-icon class="size-xl" slot="start" :icon="$icons.home" size="large" color="primary" />
					<ion-label class="mt-0 mb-0">Reveal in my projects</ion-label>
				</ion-item>
				<ion-item button detail="false" :routerLink="`/projects/${timestamp.task?.projectId}`">
					<ion-icon class="size-xl" slot="start" :icon="$icons.apps" size="large" color="primary" />
					<ion-label class="mt-0 mb-0">Show project details</ion-label>
				</ion-item>
			</ion-popover>

			<div class="min-h-[45px] w-[1px] bg-medium order-2"></div>
		</template>

		<div class="text-center order-2">
			<p class="m-0 font-bold mb-[2px]">Time</p>
			<p class="m-0 text-medium">{{ formattedTime }}</p>
		</div>

		<div class="min-h-[45px] w-[1px] bg-medium order-2"></div>

		<div class="flex ion-align-items-center order-2">
			<ion-icon v-if="isOvertracked" :icon="$icons.alert" class="size-xxl overtracked"/>
			<div class="text-center">
				<p class="m-0 font-bold mb-[1px]">Duration</p>
				<p class="m-0 text-medium">{{ formattedDuration }}</p>
			</div>
		</div>

		<div class="flex items-center order-2">
			<ion-icon :icon="$icons.logoUsd" class="billable mr-1 lg:mr-2 <sm:mr-0.5 <md:text-[14px]" :class="{active: timestamp.billable}" @click.stop="toggleBillable()"/>

			<ion-icon :icon="$icons.trashOut" class="text-[20px] md:text-[22px]" @click.stop="openDeleteTimeAlert()"></ion-icon>
		</div>
	</div>
</template>

<script>
import ModalTask from "./modals/modal-task.vue"

import openTimestampModal from "./modals/modal-time-stamp"

import axios from '@/plugins/w/axios/models/axios'
const api = {
	editTime: async (timeId, values) => axios.patch_auth_data(`/times/${timeId}`, values),
	deleteTime: async (timeId) => await axios.delete_auth_data(`/times/${timeId}`)
}

export default {
	props: {
		timestamp: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			redirectsClickEvent: null,
			isRedirectsOpen: false
		}
	},

	emits: ["edit", "delete"],

	computed: {
		formattedTime() {
			return new Date(this.timestamp.start).format("HH:mm") + " - " + new Date(this.timestamp.end).format("HH:mm")
		},

		formattedDuration() {
			return Date.time(this.timestamp.duration).format("k'h' mm'm'")
		},

		taskName() {
			return this.timestamp.taskName ?? this.timestamp.task?.name
		},

		projectName() {
			return this.timestamp.projectName ?? this.timestamp.task?.projectName
		},

		isOvertracked() {
			return this.timestamp.duration >= 57600
		}
	},

	methods: {
		openDeleteTimeAlert() {
			return this.$popups.confirm(`<b>Do you want to delete this time entry?</b>`, "Delete time?", "Delete", this._deleteTime);
		},

		openRedirects(ev) {
			this.redirectsClickEvent = ev
			this.isRedirectsOpen = true
		},

		async openEditTimeModal() {
			const newTime = await openTimestampModal(this.timestamp, "Edit time", this.openDeleteTimeAlert);
			if (!newTime)
				return

			// Remove unchanged
			newTime.start = new Date(this.timestamp.start).set({ seconds: 0 }).equals(newTime.start) ? undefined : newTime.start
			newTime.end = new Date(this.timestamp.end).set({ seconds: 0 }).equals(newTime.end) ? undefined : newTime.end
			newTime.comment = newTime.comment == this.timestamp.comment ? undefined : newTime.comment
			newTime.billable = newTime.billable == this.timestamp.billable ? undefined : newTime.billable

			await this._editTime(newTime.start, newTime.end, newTime.comment, newTime.billable)
		},

		async openModalTask() {
			await this.$modals.open(ModalTask, { task: this.timestamp.task })
		},

		async toggleBillable() {
			try {
				if (this.billablePending)
					return
				const newTime = await (this.billablePending = api.editTime(this.timestamp._id, { billable: !this.timestamp.billable }));
				this.$emit("edit", newTime);

				this.billablePending = null;
			}
			catch (err) {
				console.error(err)
			}
		},

		async _editTime(start, end, comment, billable) {
			if (start == undefined && end == undefined && comment == undefined && billable == undefined)
				return

			await this.$wLoader.loadAction(async () => {
				try {
					const newTime = await api.editTime(this.timestamp._id, { start, end, comment, billable });
					this.$emit("edit", newTime);
				} catch (err) {
					console.error(err);
				}
			}, "Editing time entry...")
		},

		async _deleteTime() {
			await this.$wLoader.loadAction(async () => {
				try {
					await api.deleteTime(this.timestamp._id);
					this.$emit("delete", this.timestamp);
				} catch (err) {
					console.error(err);
				}
			}, "Deleting time entry...")
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../_theme/billable-btn.sass'

.overtracked
		color: var(--ion-color-danger)

.line-clamp-2
	overflow: hidden
	display: -webkit-box
	-webkit-line-clamp: 2
	-webkit-box-orient: vertical

@media (min-width: 640px)
	p
		font-size: 14.5px

@media (max-width: 640px)
	p
		text-align: left !important
</style>