import { normalize as norm, filterByName } from "@/plugins/w@util/accents/index";

export function normalize(query) {
	return norm(query?.trim() ?? "")
} 

export function getTasks(tasks, predicate, showTasks = true) {
	return tasks && showTasks ? tasks.filter(task => !task?.completed && (predicate?.(task) ?? true)) : []
}

export function search(query, projects, showTasks = true) {
	if (!projects?.length)
		return []

	try {
		projects = JSON.parse(JSON.stringify(projects)); //Clone contents to not affect their original original instance
		return projects.filter(proj => filterByName(proj.name, query) || (proj.tasks = getTasks(proj?.tasks, task => filterByName(task.name, query), showTasks))?.length); //Search filtering by query matching the name of project or its task
	}
	catch (e) {
		return []
	}
}