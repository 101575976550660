<template>
	<Z-modal name="Create task">
		<div class="content h-full flex flex-col ion-justify-flex-start">
			<div class="w-full mb-3 mt-1">
				<h2>Task Name:</h2>
				<ion-input v-model="taskName" :maxlength="250" ref="inputText" />
			</div>
			<div class="w-full">
				<h2>Project:</h2>

				<ion-toolbar class="project-info flex ion-align-items-center ml-1 mb-2" @click="openProjectsSelectModal()">
					<img class="project-image mr-1" slot="start" src="@/plugins/app/_assets/wzo.jpg" />

					<h4 v-if="projectChoice?.contactName" class="customer">
						{{ projectChoice.contactName }}
					</h4>
					<h4>{{ projectChoice?.name || 'no project selected' }}</h4>

					<ion-icon :icon="$icons.listSharp" class="project-select-icon" slot="end" />
				</ion-toolbar>
			</div>
		</div>

		<template #footer>
			<ion-button class="create-task-button w-full" @click="createTask()">Create Task</ion-button>
		</template>
	</Z-modal>
</template>

<script>
import TaskProjectSelect from './modal-task-project-select'

import axios from '@/plugins/w/axios/models/axios'
const api = {
	addNewTask: async (name, projectId) => await axios.post_auth_data('/tasks', {name, projectId})
}

export default {
	props: {

	},

	data() {
		return {
			isProjectModalOpen: false,
			projectChoice: '',
			taskName: ''
		}
	},

	mounted() {
		setTimeout(() => this.$refs.inputText.$el.setFocus(), 10)
	},

	methods: {

		async createTask() {
			try {
				const taskName = this.taskName.trim()
				if (!this.projectChoice._id || !taskName) return this.$toast.error('Task name or project missing')

				const newTask = await api.addNewTask(taskName, this.projectChoice._id)
				this.$eventBus.emit('updateTask', newTask)

				this.$eventBus.emit('createdTask', { newTask, project: this.projectChoice})
				this.closeModal()
			} catch (err) {
				console.error(err)
			}
		},

		// project selection

		async openProjectsSelectModal() {
			const projectModal = await this.$modals.open(TaskProjectSelect, null, { handle: false, cssClass: 'ion-modal-1' })

			const { data } = await projectModal.onDidDismiss()
			if (data) 
				this.projectChoice = data
		},

		closeModal() {
			this.$modals.dismiss()
		},
	},
}
</script>

<style lang="sass" scoped>
.content
	box-shadow: inset 0 6px 8px -8px rgb(0 0 0 / 30%)
	padding: 10px 13px 0 !important
	overflow-y: auto

h2
	font-size: 18px
	color: var(--ion-color-medium-tint)
	margin-top: 0
	line-height: 18px

h3
	line-height: 23px

h4
	margin: 0

.project-image
	width: 55px
	height: 55px
	object-fit: cover
	border-radius: 100px
	border: 1px solid var(--ion-color-medium-tint)

.project-info
	--background: transparent !important
	cursor: pointer
	margin: 0

	.project-select-icon
		font-size: 25px
		color: var(--ion-color-medium-tint)

.customer
	color: var(--ion-color-medium-tint)
</style>