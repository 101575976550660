<template>
	<div>
		<div v-if="!items">
			<Z-profile-skelet v-if="skelet == 'profile'" />
			<Z-comments-skelet v-else-if="skelet == 'comments'" />
			<A-tasks-skelet v-else-if="skelet == 'tasks'" />
			<Z-categories-skelet v-else-if="skelet == 'categories'" />
			<Z-discover-skelet v-else-if="skelet == 'discover'" />
			<Z-channel-subscribe v-else-if="skelet == 'channel-subscribe'" />
			<Z-spinner v-else-if="skelet == 'spinner'" />
			<A-time-skelet v-else-if="skelet == 'time'" />

			<div v-else-if="skelet == 'none'"></div>
			<Z-spinner v-else />
		</div>

		<div v-if="typeof items === 'object'">
			<div
				v-if="items && !Object.keys(items).length"
				class="ion-padding loadable-message"
			>
				<p>{{ messageEmpty }}</p>
			</div>
			<div
				v-else-if="items && items.error"
				class="ion-padding loadable-message"
			>
				<p>{{ messageError }} {{ utils.parseToErrors(items.error) }}</p>
			</div>
			<slot v-else-if="items && Object.keys(items).length"></slot>
		</div>

		<div v-else-if="Array.isArray(items)">
			<div v-if="items && !items.length" class="ion-padding loadable-message">
				<p>{{ messageEmpty }}</p>
			</div>
			<slot v-else-if="items && items.length"></slot>
		</div>

		<div v-else-if="items == true">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import ZProfileSkelet from './z-profile-skelet.vue'
import ZCommentsSkelet from './z-comments-skelet.vue'
import ATasksSkelet from './a-tasks-skelet.vue'
import ZCategoriesSkelet from './z-categories-skelet.vue'
import ZDiscoverSkelet from './z-discover-skelet.vue'
import ZChannelSubscribe from './z-channel-subscribe.vue'
import ZSpinner from './z-spinner.vue'
import ATimeSkelet from './a-time-skelet.vue'

import utils from '@/plugins/w/toast/utils/toast.utils'

export default {
	props: {
		items: {
			type: [Object, Array, Boolean],
			default: null,
		},

		skelet: {
			type: String,
			default: 'profile',
		},

		messageEmpty: {
			type: String,
			default: 'No items found.',
		},

		messageError: {
			type: String,
			default: 'Error. We could not load requested data.',
		},
	},

	components: {
		ZProfileSkelet,
		ZCommentsSkelet,
		ATasksSkelet,
		ZCategoriesSkelet,
		ZDiscoverSkelet,
		ZChannelSubscribe,
		ZSpinner,
		ATimeSkelet,
	},

	data() {
		return {
			utils,
		}
	},
}
</script>

<style scoped>
.loadable-message {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loadable-message p {
	margin: 0 20px;
	text-align: center;
	color: var(--ion-color-medium-tint);
}

</style>
